import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8'
    }
};

/* axios.defaults.baseURL = 'https://stam.leonardoschool.co.il/server'; */

export const login = async (formData, setUser, setErrMessage) => {

    try {

        let response = await axios.post(`${window.apiBasePath}/login`,formData, config)
        
        console.log(response)

        if (response.data.auth) {
            console.log(`In Login: Role=`)
            console.log(jwtDecode(response.data.token).role);
            
            setUser(jwtDecode(response.data.token).role);
            
            // t.b.d. - to store the token as a cookie,
            // from security point of view
            
            sessionStorage.setItem('token',response.data.token);
            setErrMessage(' ');
        
            return true;
        } else {
            setUser('guest');

            sessionStorage.removeItem('token');
            console.log(`Auth failed`);
            console.log(response.data)
            setErrMessage(response.data.message)
            return false;
        }

    } catch(err)  {
        console.log(err)
        if (err.response) {
            console.log('err.response')
            // The client was given an error response (5xx, 4xx)
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            setErrMessage('Fix Error Message from auth.logout err.response')
        } else if (err.request) {
            console.log('err.request')
            console.log(err.request)
            setErrMessage('Fix Error Message from auth.logout err.request')
            // The client never received a response, and the request was never left
        } else {
            console.log('other err')
            setErrMessage('Server error. OOOps')
        }
        setUser('guest');
        return false
    }

}

export const getUser = () => {

    let token = sessionStorage.getItem('token');
    let role = token ? jwtDecode(token).role : 'guest';
    console.log('user:');
    console.log(role);

    return role;

}

export const logout = (setUser) => {

    setUser('guest');
    sessionStorage.removeItem('token');

}