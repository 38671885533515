import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: "#f5f5f5"
    }
  }
});

function RegistrationPage() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        minHeight="100vh"
        bgcolor="background.default"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ padding: 4, bgcolor: 'white' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography component="h1" variant="h5" gutterBottom>
                הרשמה
              </Typography>
              <form style={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="שם פרטי"
                  name="firstName"
                  autoComplete="firstName"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="שם משפחה"
                  name="lastName"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="מייל"
                  type="email"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="פלאפון"
                  name="phone"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="address"
                  label="כתובת"
                  name="address"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="סיסמא"
                  type="password"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="אישור סיסמא"
                  type="password"
                />
                <Box mt={4} pb={2}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="אני מאשר קבלת דיוורים"
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  הרשמה
                </Button>
              </form>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default RegistrationPage;