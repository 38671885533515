import charityColumns from './charityColumns';
import eventColumns from './eventColumns';
import logColumns from './logColumns';
import orderColumns from './orderColumns';
import paymentColumns from './paymentColumns';
import userColumns from './userColumns';

export const columns = {
  charity: charityColumns,
  event: eventColumns,
  log: logColumns,
  order: orderColumns,
  payment: paymentColumns,
  user: userColumns,
}

