import './LogTable.css';
import { Typography } from '@mui/material';
import DataTable from '../../components/dataTable/DataTable';

export default function LogTable() {
  return (
    // React Fragment - we need it to surround temporarily  
    // several separate elements
    <div className="logTable">

      <Typography component='h3' variant='h3'>לוגים</Typography>

      <DataTable what="log" />

    </div>
  )
}
