import axios from 'axios';

const config = {
    headers:{
      "content-type": 'application/json;charset=utf-8'
    }
  };

export const getAll = async (what) => {

    try {
        // what = "log"
        let response = await axios.get(`${window.apiBasePath}/${what}`,config);

        console.log('response:');
        console.log(response.data);

        return response.data.result;
        
    } catch(err)  {

        console.log(err);
        return [];
        
    }
}