const paymentColumns = [
  { field: "id", headerName: "מס'", width: 40 },

/** צריך להשלים עבור העמודות מהטבלה
 * payments
 */

  {
    field: "create_date",
    headerName: "תאריך יצירה",
    width: 90,
  },
  {
    field: "update_date",
    headerName: "תאריך עדכון",
    width: 90,
  },
  {
    field: "who_updated",
    headerName: "מי עדכן",
    width: 90,
  }

];

export default paymentColumns;