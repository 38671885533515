import './Hero.css';
import heroImg from '../../assets/logo/Chabad-logo.png';

import {logout} from '../../services/auth';
import ShoppingCart from '../shoppingCart/ShoppingCart';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function Hero({role,title,setUser}) {

  const navigate = useNavigate();

  return (
    <Toolbar className="hero" 
             sx={{borderBottom:1,borderColor:'divider',
             justifyContent: 'space-between'}}>
        <Box component="div" sx={{ display: 'flex', gap:2,
                  alignItems: 'center'}}>
        <Box component="img" src={heroImg} alt="חב''ד לוגו" 
          sx={{maxHeight:"50px"}} />
        <Typography component="h1" variant="h1" 
        className='stamTitle'>סת"ם זה לא סתם</Typography>
        </Box>
        
        <Typography component='h2' variant='h5'
            align="center" noWrap sx={{flex:1,
                                      display:{xs:'none',md:'block'}}} >
          {title}
        </Typography>
        
        <div className="left">
          <ShoppingCart />
          <IconButton><SearchIcon /></IconButton>
            {   
                role === 'guest' ?
                <Button color='warning' variant='contained' 
                    className='login' 
                    onClick={()=>{navigate('/login')}}>
                      התחבר
                </Button>:
                <Button color='warning' variant='contained' 
                    className='logout'
                    onClick={()=>{logout(setUser); navigate('/')}}>
                      התנתק
                </Button>
            }
        </div>
    </Toolbar>
  )
}
