import React from 'react';
import { Box, ThemeProvider, createTheme } from "@mui/system";
import { Card } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
    },
  },
});

const Muz1 = ({ product }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        className="box"
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 1,
          margin: 'auto',  // Center the box on the page
          maxWidth: '600px', // Set a maximum width for the box
        }}
      >
         <Box sx={{ color: "text.secondary", fontSize: 24, fontWeight: 'bold' }}>{product.title}</Box>
        <Box sx={{ textAlign: 'center', marginY: 2 }}>
          <img src={require('../../assets'+product.img)} alt={product.title} style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
        <Box
          sx={{
            color: "success.dark",
            fontWeight: "bold",
            fontSize: 20,
            marginBottom: 1
          }}
        >
          {product.price}
        </Box>
        <Box sx={{ color: "text.secondary", fontSize: 16 }}>
          {product.description}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Muz1;
