import './Payment.css';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


export default function Payment() {

    const navigate = useNavigate();

    return (
       <div className='payment1'> 
        <Box className='paymentContainerPhase1'>
            <Box>
                <h1>העגלה שלי(מספר הפריטים)</h1>
            </Box>
            <Box className='productsList'>
                {/* Display products here */}
                <Typography variant="body1">
                    {`% רשימה של המוצרים- טבלה + מחירים%`}
                    <Box>כמות</Box>
                </Typography>
            </Box>
                <h2>סכום ביניים: ***&#8362;</h2>
            <div className='buttons'>
                <Button variant="contained" color="warning" 
                          onClick={()=>navigate('/products')} sx={{ mt: 2 }}>
                    חזור לעמוד המוצרים
                </Button>
                <Button variant="contained" color="warning" 
                            onClick={()=>navigate('/payment-page')} sx={{ mt: 2 }}>
                    לתשלום
                </Button>
            </div>
        </Box>
       </div>
    );
}

