const logColumns = [
  { field: "id", headerName: "מס'", width: 40 },

  {
    field: "message_type",
    headerName: "סטטוס",
    width: 90,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.message_type.toLowerCase()}`}>
          {params.row.message_type}
        </div>
      );
    },
  },

  {
    field: "message_text", headerName: "הודעה", width: 160,
  },

  {
    field: "img_for_example",
    headerName: "תמונה לדוגמא",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={require(`../assets/${params.row.img_for_example}`)} alt={`${params.row.img_name} attached`} />
        </div>
      );
    },
  },

  {
    field: "img_for_example2",
    headerName: "עוד תמונה לדוגמא",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={require(`../assets/${params.row.img_for_example}`)} alt={`${params.row.img_name} attached`} />
          {params.row.img_name + ' image attached'}
        </div>
      );
    },
  },

  {
    field: "online_img_example",
    headerName: "תמונה אונליין לדוגמא",
    width: 120,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.online_img_example} alt={`פרח כלשהו`} />
        </div>
      );
    },
  },

  {
    field: "full_message",
    headerName: "הודעה מלאה",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.message_type==='ERROR' ? '!!!ERROR!!!ERROR!!!' 
             : params.row.message_type==='INFO' ? 'INFO:' : '!WARNING!'} \n ${params.row.message_text}`}
        </div>
      );
    },
  },

  {
    field: "create_date",
    headerName: "תאריך יצירה",
    width: 90,
  },
  {
    field: "update_date",
    headerName: "תאריך עדכון",
    width: 90,
  },
  {
    field: "who_updated",
    headerName: "מי עדכן",
    width: 90,
  }

];

export default logColumns;