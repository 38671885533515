import './Home.css'
import { Typography }
   from '@mui/material';

export default function Home({role}) {
  return (
    // React Fragment - we need it to surround temporarily  
    // several separate elements
    <div className="home">
      <Typography component='h1' variant='h2'>
        שלום, {role==='guest'?'אורח':role==='client'?'לקוח':'מנהל'} יקר!
      </Typography>
      <Typography component='h2' variant='h2'>אנחנו העמותה החמודה בשוק</Typography>
    </div>
  )
}
