const eventColumns = [
  { field: "id", headerName: "ID", width: 40 },
  {
    field: "event_name",
    headerName: "Event Name",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.avatar} alt={params.row.avatar_alt} />
          {`${params.row.event_type} + ${params.row.event_name}`}
        </div>
      );
    },
  },
  {
    field: "user_name",
    headerName: "Full Name",
    width: 130,
    renderCell: (params) => {
      return (
        <div>
          {`${params.row.first_name} ${params.row.last_name}`}
        </div>
      );
    },
  },
  {
    field: "date_and_hour",
    headerName: "Date and Hour",
    width: 230,
  },

  {
    field: "address",
    headerName: "Address",
    width: 100,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 100,
  },
  {
    field: "create_date",
    headerName: "Create Date",
    width: 100,
  },
  {
    field: "update_date",
    headerName: "Update Date",
    width: 100,
  },
  {
    field: "who_updated",
    headerName: "Who Updated",
    width: 100,
  },
];

export default eventColumns;


