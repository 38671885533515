import './Charity.css'
import { Input, InputLabel, FormControl, FormHelperText , }
   from '@mui/material';
import FormGroup from '@mui/material/FormGroup';


export default function Charity() {
  return (
    <div className="charity">
      <header>
        <h1>פרטים אישיים</h1>
      </header>
    <FormGroup>

    

    <FormControl>
            <InputLabel htmlFor="my-input">כתובת מייל:</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">לעולם לא נשתף את כתובת המייל שלך.</FormHelperText>
        </FormControl>



        <FormControl>
            <InputLabel htmlFor="my-input"> שם מלא:</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">זהו שדה חובה.</FormHelperText>
        </FormControl>

            
        <FormControl>
            <InputLabel htmlFor="my-input"> מספר טלפון:</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">זהו שדה חובה.</FormHelperText>
        </FormControl>

        <FormControl>
            <InputLabel htmlFor="my-input"> מספר כרטיס אשראי:</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">זהו שדה חובה.</FormHelperText>
        </FormControl>

        <FormControl>
            <InputLabel htmlFor="my-input">תוקף:</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">זהו שדה חובה.</FormHelperText>
        </FormControl>

        <FormControl>
            <InputLabel htmlFor="my-input">cvv:</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">זהו שדה חובה.</FormHelperText>
        </FormControl>



        </FormGroup>


    </div>
  )
}
