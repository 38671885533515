import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Link,useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {login} from '../../../services/auth';

const theme = createTheme({
  palette: {
    background: {
      default: "#f5f5f5"
    }
  }
});

function LoginPage({setUser}) {

  const [formData, setFormData] = useState({username:'',password:''});
  const [errMessage, setErrMessage] = useState(' ');

  const navigate = useNavigate();
  const onSubmit = async (e) => {

    e.preventDefault();

    if (formData.password && formData.username) {

      if (await login(formData,setUser,setErrMessage)) {

        console.log('inside true')
        setFormData({username:'',password:''})
        navigate('/');

      }

    } else {
      setErrMessage(!formData.username ? 'חסר אי-מייל' : 'חסרה סיסמא')
    }

  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        minHeight="100vh"
        bgcolor="background.default"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="xs">
          <Paper elevation={3} sx={{ padding: 4, bgcolor: 'white',position:'relative' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton sx={{ position: "absolute", top: "2px", right: "2px" }}
                      onClick={()=>navigate(-1)}>
                <CloseIcon />
              </IconButton>
              <Typography component="h1" variant="h5" gutterBottom>
                כניסה לחשבון
              </Typography>
              <form onSubmit={onSubmit} style={{ width: '100%' }}>
                

                <FormHelperText error>
                  {errMessage}
                </FormHelperText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="שם משתמש"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  onChange={e=>{setFormData({...formData, 
                                  username:e.target.value})}}
                  value={formData.username}
                  // controlled INPUT
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="סיסמא"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={e=>{setFormData({...formData, 
                    password:e.target.value})}}
                  value={formData.password}
                />

                <h3>אין לך חשבון? <Link to='/signup'>הרשם</Link></h3>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  כניסה
                </Button>
              </form>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default LoginPage;