import './Navbar.css';

import {Link as MuiLink} from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { Box, Divider, Drawer, IconButton, List, 
  ListItem, 
  ListItemButton, ListItemText, 
  Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

export default function Navbar({menuAr, window}) {

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        סת"ם זה לא סתם
      </Typography>
      <Divider />
      <List>
        {menuAr.map((item) => (
          <ListItem key={`${item.title}_${item.url}`} disablePadding>
            <ListItemButton component={Link} to={item.url} 
               sx={{ textAlign: 'center' }}>
              
              {item.icon && <item.icon />}<ListItemText primary={item.title} />
              
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  const menu = menuAr.map(item => 
  
    <MuiLink component={Link} to={item.url} noWrap variant='overline'
          underline='hover' color='warning'
          sx={{p:1, flexShrink:0}} 
          key={`${item.title}_${item.url}`} >
        {item.title}{item.icon && <item.icon />}
    </MuiLink>

  )

  const container = window !== undefined 
       ? () => window().document.body : undefined;

  return (
    <Box component="nav" className="navbar">
      <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mx: 5, display: { md: 'none' },
                 fontSize: '3rem' }}
          >
            <MenuIcon 
                sx={{fontSize: '3rem'}} />
          </IconButton>
      <Toolbar className="navbar" 
        component='nav' variant='regular'
             sx={{ display: {xs: 'none',md: 'flex'},
             justifyContent:'space-between',
                  overflowX:'auto'}}>
        {menu}
      </Toolbar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    
    </Box>
  )
}
