import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';

import './App.css';
import Hero from './components/hero/Hero';
import Navbar from './components/navbar/Navbar';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { userMenu } from './data/menuData';
import { getUser } from './services/auth';

import About from './pages/about/About';
import LogTable from './pages/logTable/LogTable';
import Home from './pages/home/Home';
import SviutRatson from './pages/sviut-ratson/SviutRatson';
import LoginPage from './pages/user/log-in/log-in';
import UserTable from './pages/user/UserTable';
import RegistrationPage from './pages/user/sing-up/SingUp';
import Stories from './pages/stories/stories';
import OneStory from './pages/stories/OneStory';
import Payment from './pages/payment/PaymentPhase1';
import PaymentPage from './pages/payment/PaymentPhase2';
import PaymentTable from './pages/payment/PaymentTable';
import Muz from './pages/Muzarim/Muz';
import Muz1 from './pages/Muzarim/Muz1';
import Charity from './pages/charity/Charity';
import UnderConstruction from './pages/underConstruction/UnderConstruction';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import { ThemeProvider, createTheme } from "@mui/material/styles";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin]
});

const mainTheme = createTheme({ 
  direction: "rtl",
  typography: {
    h1: {
      fontFamily: "Times New Roman",
      fontSize: "3rem",
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '2.1rem',
      }
    }
  }
});


window.apiBasePath="https://stam-api.leonardoschool.co.il";
/* window.apiBasePath="https://stam.leonardoschool.co.il/server"; */
/* window.apiBasePath="http://localhost:3015"; */

function App() {

  const [ role, setRole ] = useState(getUser());

  const productTmp = {
    title: 'תפילין',
    img:"/img/products/tfiling.jpg",
    description:"תפילין חב''ד",
    price:"560$",
  }

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={mainTheme}>
    <div className="App">
      <header>
        <Hero role={role} title="ברוכים הבאים!" 
              setUser={setRole} />

        <Navbar 
          menuAr={userMenu[role]} />

      </header>
      <main className='appMain'>
      
        <Routes>

          <Route path='/' element={<Home role={role} />} />

          <Route path='/login' element={<LoginPage setUser={setRole} />} />
          <Route path='/signup' element={<RegistrationPage />} />

          <Route path='/about' element={<About />} />
          <Route path='/charity' element={<Charity />} />
          {/* <Route path='/charity-list' element={<CharityTable />} /> */}
          <Route path='/logs' element={<LogTable />} />
          <Route path='/users' element={<UserTable />} />
          {/* <Route path='/orders' element={<Orders />} /> */}
          <Route path='/payment' element={<Payment />} />
          <Route path='/payment-page' element={<PaymentPage />} />
          <Route path='/payments' element={<PaymentTable />} />
          <Route path='/one-product' element={<Muz1 product={productTmp} />} />
          <Route path='/products' element={<Muz />} />
          <Route path='/one-story' element={<OneStory />} />
          <Route path='/stories' element={<Stories />} />

          {/* <Route path='/shopping-cart' element={<ShoppingCart />} /> */}
          <Route path='/sviut-ratson' element={<SviutRatson />} />

          <Route path='*' element={<UnderConstruction />} />

        </Routes>

      </main>
    </div>
    </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
