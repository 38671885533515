import './DataTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { columns } from "../../data/datatableSource";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import { getAll } from '../../services/getData';


const DataTable = ({what}) => {

  console.log('WHAT:',what)

    const listOfColumns = columns[what];

  console.log(`listOfColumns:`)
  console.log(listOfColumns)
    const [data, setData] = useState([]);

    useEffect(() => {

      const getNow = async (what) => {

        setData(await getAll(what));

      }
      getNow(what);
      

    },[what])

    const handleDelete = (id) => {

      setData(data.filter(row => row.id !== id));

    }

    const actionColumn = [
        {
          field: "action",
          headerName: "Action",
          width: 200,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                <Link to={`/${what}/test`} style={{ textDecoration: "none" }}>
                  <div className="viewButton">View</div>
                </Link>
                <div
                  className="deleteButton"
                  onClick={() => handleDelete(params.row.id)}
                >
                  Delete
                </div>
              </div>
            );
          },
        },
    ];

    console.log("data data data")
    console.log(data)
    return (
        <div className="datatable">
          <div className="datatableTitle">
            
            <Link to={`/${what}/new`} style={{textDecoration:"none"}}>
              הוספה
            </Link>
          </div>
          <DataGrid className="datagrid"
              rows={data}
              columns={listOfColumns.concat(actionColumn)}
              pageSize={5}
              pageSizeOptions={[5,100]}
              rowsPerPageOptions={[5]}
              checkboxSelection
          />
        </div>
    )
}

export default DataTable;