import "./Muz.css";
import { Input, InputLabel, FormControl, 
  FormHelperText, Link as MuiLink } from "@mui/material";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import { Link } from 'react-router-dom';

import { Card } from "@mui/material";
const theme = createTheme({
    palette: {
      background: {
        paper: "#fff",
      },
      text: {
        primary: "#173A5E",
        secondary: "#46505A",
      },
      action: {
        active: "#001E3C",
      },
      success: {
        dark: "#009688",
      },
    },
  });
  
const arr = [
    {
        title:"תפילין",
        img:"/img/products/tfiling.jpg",
        description:"תפילין חב''ד",
        price:"560$",
        link: '/one-product',
      },
      {
        title:"תפילין",
        img:"/img/products/tfiling.jpg",
        description:"תפילין אשכנז",
        price:"560$",
        link: '/one-product',
      },
      {
        title:"תפילין",
        img:"/img/products/tfiling.jpg",
        description:"תפילין ספרד",
        price:"560$",
        link: '/one-product',
      },
      {
        title:"מזוזה",
        img:"/img/products/mezuza.webp",
        description:"חב''ד",
        price:"140$",
        link: '/one-product',
      },
      {
        title:"מזוזה",
        img:"/img/products/mezuza.webp",
        description:"אשכנז",
        price:"140$",
        link: '/one-product',
      },
      {
        title:"מזוזה",
        img:"/img/products/mezuza.webp",
        description:"ספרד",
        price:"140$",
        link: '/one-product',
      }
    ]

const muzArr = arr.map((item,inx) => (
    <ThemeProvider theme={theme} key={inx+`_`}>
    <Box className="box"
      sx={{
        bgcolor: "background.paper",
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
        minWidth: 400,
        border: 1,
       
      }}
    >
      {/* <MuiLink component={Link} to={item.link} > */}
      <Link to={item.link} >
       <Box sx={{ color: "text.primary", fontSize: 20 }}>{item.title}</Box>
      <Box component="img" src={require("../../assets" + item.img)} 
      sx={{ width: "100%" }}
      alt={item.img}>
       
      </Box>
      <Box
        sx={{
          color: "success.dark",
          display: "inline",
          fontWeight: "bold",
          mx: 0.5,
          fontSize: 18,
          width:'20px'
        }}
      >
        {item.price}
      </Box>
      <Box sx={{ color: "text.secondary", display: "inline", fontSize: 14 }}> {item.description}
      </Box>
      </Link>
      {/* </MuiLink> */}
    </Box>
  </ThemeProvider>
)) 



export default function Muz() {
  return (
  <> 
  {muzArr}
  </>
  );
}
