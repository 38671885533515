import './ShoppingCart.css';
import { useNavigate } from 'react-router-dom';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';

function ShoppingCart() {

  const navigate = useNavigate();

  return (
    <IconButton onClick={() => {navigate('/shopping-cart')}}>
        <ShoppingCartIcon sx={{color:'var(--orange)'}} />
    </IconButton>
  )
}

export default ShoppingCart;