import './About.css'
import { Typography }
   from '@mui/material';

export default function About() {
  return (
    // React Fragment - we need it to surround temporarily  
    // several separate elements
    <div className="about">
      <Typography component='h2' variant='h2'>אודותינו</Typography>
      <Typography component='h2' variant='h2'>אנחנו העמותה החמודה בשוק</Typography>
      <Typography component='h3' variant='h3'>המזוזות אצלינו הכי מופלאות</Typography>
    </div>
  )
}
