import './UserTable.css';
import { Typography } from '@mui/material';
import DataTable from '../../components/dataTable/DataTable';

export default function UserTable() {
  return (

    <div className="userTable">

      <Typography component='h3' variant='h3'>משתמשים</Typography>

      <DataTable what="user" />

    </div>
  )
}
