import './PaymentPhase2.css';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Input, InputLabel, FormControl, FormHelperText }
   from '@mui/material';

export default function PaymentPage({}) {

    const navigate = useNavigate();

    return (
        <Box className='paymentContainerPhase2'>
            <Toolbar>
                <h1 variant="h6">לתשלום</h1>
            </Toolbar>
            <Box>
                <div className='form'>
                    <FormControl sx={{m:2}}>
                        <InputLabel htmlFor="my-input">שם פרטי</InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>
                    <FormControl sx={{m:2}}>
                        <InputLabel htmlFor="my-input">שם משפחה</InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>
                    <FormControl sx={{m:2}}>
                        <InputLabel htmlFor="my-input">מספר טלפון</InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>
                    <FormControl sx={{m:2}}>
                        <InputLabel htmlFor="my-input">דואר אלקטרוני</InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>
                    {/** 
                    <FormControl sx={{m:2}}>
                        <InputLabel htmlFor="my-input">תעודת זהות</InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>
                    <FormControl sx={{m:2}}>
                        <InputLabel htmlFor="my-input"></InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>
                    <FormControl sx={{m:2}}>
                        <InputLabel htmlFor="my-input">כתובת אימייל</InputLabel>
                        <Input id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>*/}
                </div>

                    <h2 variant="body1">סה"כ לתשלום: 100 &#8362;</h2>
                    <h2 variant="body1">בחר צורת תשלום:
                    <Button variant='contained' color='primary'>ביט</Button>
                    </h2>
            </Box>
                <Button variant="contained" color="warning" 
                                   onClick={()=>navigate(-1)} sx={{ mt: 2 }}>
                    חזור
                </Button>
        </Box>
    );
};
