import './SviutRatson.css'
import { Input, InputLabel, FormControl, FormHelperText }
   from '@mui/material';

export default function SviutRatson() {
  return (
    <div className="SviutRatson">
    
        <FormControl>
            <InputLabel htmlFor="my-input">Email address</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
        </FormControl>


    </div>
  )
}
