// OneStory.jsx
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const singleStoryData = {
  image: '/img/stories/contemplative-reptile.jpg',
  title: 'Lizard',
  description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
};

export default function OneStory() {
  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 5 }}>
      <CardMedia
        sx={{ height: 300 }}
        image={"../../assets" + singleStoryData.image}
        title={singleStoryData.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h4" component="div">
          {singleStoryData.title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {singleStoryData.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
