const userColumns = [
  { field: "id", headerName: "מס'", width: 40 },

/** צריך להשלים עבור העמודות מהטבלה
 * users
 */

  {
    field: "user_type_id",
    headerName: "מספר סוג משתמש",
    width: 90,
  },
  {
    field: "first_name",
    headerName: "שם פרטי",
    width: 90,
  },
  {
    field: "last_name",
    headerName: "שם משפחה",
    width: 90,
  },
  {
    field: "email",
    headerName: "מייל",
    width: 100,
  },
  {
    field: "phone",
    headerName: "מספר פלאפון",
    width: 90,
  },
  {
    field: "address",
    headerName: "כתובת",
    width: 120,
  },
  {
    field: "password",
    headerName: "סיסמא",
    width: 90,
  },
  {
    field: "create_date",
    headerName: "תאריך יצירה",
    width: 200,
  },
  {
    field: "update_date",
    headerName: "תאריך עדכון",
    width: 200,
  },
  {
    field: "who_updated_id",
    headerName: "מי עדכן מעדכן",
    width: 90,
  }


];

export default userColumns;