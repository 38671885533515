import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChatIcon from '@mui/icons-material/Chat';
import CottageIcon from '@mui/icons-material/Cottage';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HistoryIcon from '@mui/icons-material/History';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export const userMenu = {
  guest: [
    { title: 'בית',
      url: '/',
      icon: CottageIcon},
    { title: 'תרומות',
      url: '/charity',
      icon: VolunteerActivismIcon},
    { title: 'סיפורים',
      url: '/stories',
      icon: AutoStoriesIcon},
    { title: 'מוצרים',
      url: '/products',
      icon: LocalMallIcon},
    { title: 'אודות',
      url: '/about',
      icon: ChatIcon},
    { title: 'צור קשר',
      url: '/contacts',
      icon: HeadsetMicIcon},
  ],
  client: [
    { title: 'בית',
      url: '/',
      icon: CottageIcon},
    { title: 'תרומות',
      url: '/charity',
      icon: VolunteerActivismIcon},
    { title: 'סיפורים',
      url: '/stories',
      icon: AutoStoriesIcon},
    { title: 'מוצרים',
      url: '/products',
      icon: LocalMallIcon},
    { title: 'אודות',
      url: '/about',
      icon: ChatIcon},
    { title: 'צור קשר',
      url: '/contacts',
      icon: HeadsetMicIcon},
    { title: 'תשלום',
      url: '/payment',
      icon: PaymentIcon},
    { title: 'הזמנות',
      url: '/orders/:id',
      icon: ShoppingCartIcon},
    { title: 'לו"ז',
      url: '/calendar/:id',
      icon: InsertInvitationIcon},
    { title: 'הסטוריה',
      url: '/history/:id',
      icon: HistoryIcon},
  ],
  admin: [
    { title: 'בית',
      url: '/',
      icon: CottageIcon},
    { title: 'תרומות',
      url: '/charity-list',
      icon: VolunteerActivismIcon},
    { title: 'סיפורים',
      url: '/stories',
      icon: AutoStoriesIcon},
    { title: 'מוצרים',
      url: '/products',
      icon: LocalMallIcon},
    { title: 'אודות',
      url: '/about',
      icon: ChatIcon},
    { title: 'צור קשר',
      url: '/contacts',
      icon: HeadsetMicIcon},
    { title: 'הזמנות',
      url: '/orders',
      icon: AddBusinessIcon},
    { title: 'לו"ז',
      url: '/calendar',
      icon: CalendarMonthIcon},
    { title: 'הסטוריה',
      url: '/history',
      icon: HistoryIcon},
    { title: 'תשלומים',
      url: '/payments',
      icon: CreditScoreIcon},
    { title: 'משתמשים',
      url: '/users',
      icon: PeopleOutlineIcon},
    { title: 'לוגים',
      url: '/logs',
      icon: ManageSearchIcon},
  ],
}